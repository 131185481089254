<template>
  <div>
    <location />

    <div class="p53 page">
      <div class="wrap-1200">
        <b-form @submit="onSubmit">
          <div class="select-search list">
            <select
              class="border-right-0"
              aria-label=".border-right-0 example"
              name="searchtype"
              v-model="searchData.searchtype"
            >
              <option value="1">제목</option>
              <option value="2">내용</option>
            </select>
            <input
              type="text"
              class="form-control-lg"
              name="searchname"
              v-model="searchData.searchname"
            />
            <input
              class="btn btn-primary btn-lg search"
              type="submit"
              value=""
            />
          </div>
        </b-form>
      </div>
      <table
        class="board table wrap-1200 resrc-list"
        id="dataTable"
        width="100%"
        cellspacing="0"
      >
        <thead>
          <tr>
            <th class="col-auto d-none d-lg-table-cell">번호</th>
            <th class="col-auto d-none d-lg-table-cell">구분</th>
            <th class="col-6 d-lg-table-cell">제목</th>
            <th class="col-auto d-lg-table-cell">등록일</th>
            <th class="col-auto d-none d-lg-table-cell">조회</th>
            <th class="col-auto d-none d-lg-table-cell">다운로드</th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in topItems">
            <tr
              class="btn1" @click="toggle_tr(index)" :key="index">
              <td class="col-auto d-none d-lg-table-cell">*</td>
              <td class="col-auto d-none d-lg-table-cell">
                <span class="icon badge bg-purple" v-if="item.type == 1">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-blue" v-if="item.type == 2">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-pink" v-if="item.type == 3">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-cyan" v-if="item.type == 4">
                  {{ item.type | category("list") }}
                </span>
              </td>
              <td class="col-6 d-lg-table-cell text-start">
                <router-link
                  :to="{
                    name: 'ResourcesView',
                    params: { id: item.resourcesno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname,
                    },
                  }"
                  class="more"
                >
                  {{ item.title }}
                </router-link>
                <img
                  src="/img/new.gif"
                  class="btn-toggle-arrow"
                  v-if="
                    $moment
                      .duration($moment().diff($moment(item.date)))
                      .asDays() < 8
                  "
                />
              </td>
              <td class="col-auto d-lg-table-cell text-date">
                {{ item.date | GMTtuUTC }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.cnt | comma }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <a
                  v-if="item.filename"
                  class="btn btn-primary btn-xs pr-4 pl-4" style="color:#fff;"
                  :href="item.path + '/' + item.filename"
                  download
                  >다운로드</a
                >
              </td>
              <td class="td-arrow">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none d-md-tabel-cell"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td class="align-left bg-white" colspan="6">
                <div class="layer1">
                  <div class="row g-0">
                    <div class="d-inline-block w-40">번호</div>
                    <div class="d-inline-block w-60">{{ item.no }}</div>
                  </div>
                  <div class="row g-0">
                    <div class="d-inline-block w-40">구분</div>
                    <div class="d-inline-block w-60">
                      <span
                        class="icon badge bg-purple"
                        v-if="item.type == 1"
                      >
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-blue" v-if="item.type == 2">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-pink" v-if="item.type == 3">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-cyan" v-if="item.type == 4">
                        {{ item.type | category("list") }}
                      </span>
                    </div>
                  </div>

                  <div class="row g-0">
                    <div class="d-inline-block w-40">조회</div>
                    <div class="d-inline-block w-60">
                      {{ item.cnt | comma }}
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="d-inline-block w-40">다운로드</div>
                    <div class="d-inline-block w-60">
                      <a
                        class="btn btn-primary btn-xs pr-4 pl-4" style="color:#fff;"
                        :href="item.path + '/' + item.filename"
                        download
                        >다운로드</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-for="(item, index) in items">
            <tr class="btn1" :key="index + 'l'" @click="toggle_tr(index + 'l')">
              <td class="col-auto d-none d-lg-table-cell">{{ item.no }}</td>
              <td class="col-auto d-none d-lg-table-cell">
                <span class="icon badge bg-purple" v-if="item.type == 1">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-blue" v-if="item.type == 2">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-pink" v-if="item.type == 3">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-cyan" v-if="item.type == 4">
                  {{ item.type | category("list") }}
                </span>
              </td>
              <td class="col-6 d-lg-table-cell text-start">
                <router-link
                  :to="{
                    name: 'ResourcesView',
                    params: { id: item.resourcesno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname,
                    },
                  }"
                  class="more"
                >
                  {{ item.title }}
                </router-link>
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index + 'l' }"
                  v-if="
                    $moment
                      .duration($moment().diff($moment(item.date)))
                      .asDays() < 8
                  "
                />
              </td>
              <td class="col-auto d-lg-table-cell text-date">
                {{ item.date | GMTtuUTC }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.cnt | comma }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <a
                  v-if="item.filename"
                  class="btn btn-primary btn-xs pr-4 pl-4" style="color: white;"
                  :href="item.path + '/' + item.filename"
                  download
                  >다운로드</a
                >
              </td>
              <td class="td-arrow">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none d-md-tabel-cell"
              :class="{ show: view_hide === index + 'l' }"
              :key="index + 'r'"
              v-if="view_hide === index + 'l'"
            >
              <td class="align-left bg-white" colspan="6">
                <div class="layer1">
                  <div class="row g-0">
                    <div class="d-inline-block w-40">번호</div>
                    <div class="d-inline-block w-60">{{ item.no }}</div>
                  </div>
                  <div class="row g-0">
                    <div class="d-inline-block w-40">구분</div>
                    <div class="d-inline-block w-60">
                      <span
                        class="icon badge bg-purple"
                        v-if="item.type == 1"
                      >
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-blue" v-if="item.type == 2">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-pink" v-if="item.type == 3">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-cyan" v-if="item.type == 4">
                        {{ item.type | category("list") }}
                      </span>
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="d-inline-block w-40">조회</div>
                    <div class="d-inline-block w-60">
                      {{ item.cnt | comma }}
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="d-inline-block w-40">다운로드</div>
                    <div class="d-inline-block w-60">
                      <a
                        class="btn btn-primary btn-xs pr-4 pl-4 download_btn"
                        :href="item.path + '/' + item.filename"
                        download
                        >다운로드</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center">데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/support";
import "@/filter/common";

export default {
  name: "Resources",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchtype: this.$route.query.searchtype || 1,
        searchname: this.$route.query.searchname || "",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      topItems: [],
      view_hide: null,
    };
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_resourceslist();
    },
  },
  methods: {
    get_resourceslist() {
      ax.get_resourceslist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.resourceslistcnt <= 0 && data.topresourceslist.length <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.resourceslistcnt;
          this.page_total = Math.ceil(data.resourceslistcnt / this.rows);
          this.items = data.resourceslist;
          this.topItems = data.topresourceslist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchtype: this.searchData.searchtype,
            searchname: this.searchData.searchname,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`
        : `?page=${pageNum}&searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_resourceslist();
  },
};
</script>