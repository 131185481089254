import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 공지사항 리스트
const get_noticelist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    noticelistcnt: 0,
    noticelist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/customer/noticelist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_noticelist", err);
    result(false, err);
  }
};

// 자료실 리스트
const get_resourceslist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    resourceslistcnt: 0,
    resourceslist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/customer/resourceslist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_resourceslist", err);
    result(false, err);
  }
};

// 국가리스트 리스트
const get_countrylist = async (result) => {
  let finalData = {
    code: "",
    message: "",
    countrylistcnt: 0,
    countrylist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/common/countrylist",
    //url: 'http://localhost/test.php',
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_countrylist", err);
    result(false, err);
  }
};

// 지역리스트 리스트
const get_stateslist = async (countryno, result) => {
  let finalData = {
    code: "",
    message: "",
    stateslistcnt: 0,
    stateslist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/common/stateslist/" + countryno,
    //url: 'http://localhost/test.php',
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_stateslist", err);
    result(false, err);
  }
};

// 문의글 작성
const set_inquirytwrite = async (form, result) => {
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/common/inquirywrite",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify({
      firstname: form.firstname,
      lastname: form.lastname,
      email: form.email,
      phone: form.phone,
      country: form.country,
      state: form.state,
      contents: form.contents,
      title: form.title,
      recaptcha_token: form.recaptcha_token,
    }),
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.code, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.code, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_inquirytwrite", err);
    result(false, response.data.code, err);
  }
};

// 공지사항보기
const get_noticeview = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    noticeview: [
      {
        type: "",
        title: "",
        date: "",
        cnt: "",
        contents: "",
      },
    ],
    next_post: {},
    prev_post: {},
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/customer/noticeview/" + param.noticeno,
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_noticeview", err);
    result(false, err);
  }
};

// 자료실보기
const get_resourcesview = async (param, result) => {
  console.log(param);
  let finalData = {
    code: "",
    message: "",
    resourcesview: [
      {
        title: "",
        date: "",
        cnt: "",
        contents: "",
        path: "",
        filename: "",
      },
    ],
    next_post: {},
    prev_post: {},
  };

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/customer/resourcesview/" +
      param.resourcesno,
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_resourcesview", err);
    result(false, err);
  }
};

export default {
  get_noticelist,
  get_resourceslist,
  get_countrylist,
  get_stateslist,
  set_inquirytwrite,
  get_noticeview,
  get_resourcesview,
};
