<template>
  <div>
    <locations />
    <b-form @submit="onSubmit">
      <div class="page wrap-1200 bp-w440-lt">
        <p class="info">
          문의하신 내용을 확인하여 최대한 빠르게 답변드리겠습니다.
        </p>
        <div class="row">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell">
              <span class="required">제목</span>
            </label>
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              placeholder="4글자 이상 100글자 미만"
              name="title"
              v-model="form.title"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell">
              <span class="required">성</span>
            </label>
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              placeholder="공백없이 한글, 영문, 숫자만 입력 가능 20글자 미만"
              name="firstname"
              v-model="form.firstname"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell">
              <span class="required">이름</span>
            </label>
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              placeholder="공백없이 한글, 영문, 숫자만 입력 가능 20글자 미만"
              name="lastname"
              v-model="form.lastname"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell">
              <span class="required">전화번호</span>
            </label>
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              placeholder="숫자, '-' 만 입력 가능"
              name="phone"
              v-model="form.phone"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell">
              <span class="required">국가</span>
            </label>
          </div>
          <div class="col-lg-9">
            <select name="country" v-model="form.country">
              <option selected value="">선택</option>
              <option
                :value="country.countryno"
                v-for="(country, index) in countrylist"
                :key="index"
              >
                {{ country.countryname }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell">
              <span class="required">지역</span>
            </label>
          </div>
          <div class="col-lg-9">
            <select name="state" v-model="form.state">
              <option :selected="form.state === ''" value="">시/주</option>
              <option
                :value="states.statesname"
                v-for="(states, index) in stateslist"
                :key="index"
              >
                {{ states.statesdesc }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell">
              <span class="required">이메일</span>
            </label>
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              placeholder="공백없이 영문, 숫자만 입력 가능 100글자 미만"
              name="email"
              v-model="form.email"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell">
              <span class="required">문의 내용</span>
            </label>
          </div>
          <div class="col-lg-9">
            <textarea
              name="contents"
              id=""
              cols="30"
              rows="10"
              v-model="form.contents"
            ></textarea>
          </div>
        </div>

        <div class="agree_box px-0 px-sm-3">
          <vue-recaptcha
            :sitekey="RECAPTCHA_SITEKEY"
            :loadRecaptchaScript="true"
            @verify="verify"
          ></vue-recaptcha>
        </div>

        <div class="btn-bottom-wrapper">
          <b-button type="submit" size="xl" variant="primary" aria-label="등록"
            >등록</b-button
          >
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/support";
import "@/filter/common";
import VueRecaptcha from "vue-recaptcha";
import _ from "lodash";

export default {
  name: "Inquiry",
  components: {
    locations,
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        recaptcha_yn: "n",
        recaptcha_token: "",
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        country: "",
        state: "",
        contents: "",
        title: "",
      },
      countrylist: [],
      stateslist: [],
      RECAPTCHA_SITEKEY: process.env.VUE_APP_RECAPTCHA_SITEKEY,
    };
  },
  methods: {
    verify(response) {
      this.form.recaptcha_token = response;
      this.form.recaptcha_yn = "y";
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.title === "") {
        alert("제목을 입력하세요.");
        return false;
      }

      if (this.form.firstname === "") {
        alert("성을 입력하세요.");
        return false;
      }

      if (this.form.lastname === "") {
        alert("이름을 입력하세요.");
        return false;
      }

      if (this.form.phone === "") {
        alert("전화번호를 입력하세요.");
        return false;
      }

      if (this.form.email === "") {
        alert("이메일을 입력하세요.");
        return false;
      }

      if (this.form.contents === "") {
        alert("내용을 입력해 주세요.");
        return false;
      }

      if (this.form.recaptcha_yn === "n") {
        alert("자동등록방지를 확인하세요.");
        return false;
      }

      ax.set_inquirytwrite(this.form, (flag, code, message) => {
        if (flag) {
          if (code === 200) {
            alert(message);
            //this.resetForm()
            location.reload();
          }
        } else {
          alert(message);
          return false;
        }
      });
    },
    resetForm() {
      this.form = _.cloneDeep(this.form);
      this.form.firstname = "";
      this.form.lastname = "";
      this.form.phone = "";
      this.form.email = "";
      this.form.country = "";
      this.form.state = "";
      this.form.contents = "";
      this.form.title = "";
    },
    get_countrylist() {
      ax.get_countrylist((flag, data) => {
        if (flag) {
          this.countrylist = data.countrylist;
        }
      });
    },
    get_stateslist() {
      ax.get_stateslist(this.form.country, (flag, data) => {
        if (flag) {
          this.stateslist = data.stateslist;
        }
      });
    },
  },
  created() {
    this.get_countrylist();
  },
  watch: {
    "form.country": function () {
      this.form.state = "";
      if (this.form.country != "") this.get_stateslist();
      else this.stateslist = [];
    },
  },
};
</script>
