<template>
  <div>
    <location />

    <div class="board_view page">
      <div class="wrap-1200">
        <article class="article-header row g-0">
          <div class="col-12">
            <h5>{{ resourcesview.title }}</h5>
          </div>
          <div class="d-flex flex-wrap">
            <div class="col-12 col-sm-6 text-black">
              <span class="icon badge bg-blue" v-if="resourcesview.type == 1">
                {{ resourcesview.type | category }}
              </span>
              <span
                class="icon badge bg-purple"
                v-if="resourcesview.type == 2"
              >
                {{ resourcesview.type | category }}
              </span>
              <span class="icon badge bg-pink" v-if="resourcesview.type == 3">
                {{ resourcesview.type | category }}
              </span>
              <span class="icon badge bg-cyan" v-if="resourcesview.type == 4">
                {{ resourcesview.type | category }}
              </span>

              작성자 : {{ resourcesview.regname }}
            </div>
            <div class="d-flex col-12 col-sm-6">
              <div class="ms-auto">
                <span class="me-2">
                  <i class="material-icons">date_range </i>
                  {{ resourcesview.date }}
                </span>
                <span>
                  <i class="material-icons">visibility</i>
                  {{ resourcesview.cnt | comma }}
                </span>
              </div>
            </div>
          </div>
        </article>
        <article class="article-attach" v-if="resourcesview.filename">
          <a :href="`${resourcesview.path}${resourcesview.filename}`">
            <i class="material-icons-outlined">file_download</i>
            <span>{{ resourcesview.filename }}</span>
          </a>
        </article>
        <article class="article-body">
          {{ resourcesview.contents }}
        </article>

        <article class="article-footer">
          <div class="prev" :class="!next_post.resources_pk && 'no-data'">
            <router-link
              :to="{
                name: 'ResourcesView',
                params: { id: next_post.resources_pk },
              }"
            >
              <i class="material-icons-outlined"> expand_less </i>
              <span>{{ next_post.title || "이전 글이 없습니다." }}</span>
            </router-link>
          </div>
          <div class="next" :class="!prev_post.resources_pk && 'no-data'">
            <router-link
              :to="{
                name: 'ResourcesView',
                params: { id: prev_post.resources_pk },
              }"
            >
              <i class="material-icons-outlined"> expand_more </i>
              <span>{{ prev_post.title || "다음 글이 없습니다." }}</span>
            </router-link>
          </div>
        </article>
        <div class="btn-bottom-wrapper">
        <b-button size="xl" variant="outline-primary" aria-label="목록" @click="
              $router.push({
                name: 'Resources',
                query: {
                  searchtype: $route.query.searchtype,
                  searchname: $route.query.searchname,
                },
              })
            ">목록</b-button>
        </div>
      </div>
      <!--       <div class="wrap-1200 start-m row g-0 center-btn">
        <div class="col-lg-4 d-table m-auto">
          <button
            class="btn btn-primary btn-xl"
            type="button"
            @click="
              $router.push({
                name: 'Resources',
                query: {
                  searchtype: $route.query.searchtype,
                  searchname: $route.query.searchname,
                },
              })
            "
          >
            목록
          </button>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/support";
import "@/filter/common";

export default {
  name: "ResourcesView",
  components: {
    location,
  },
  data() {
    return {
      param: {
        searchtype: this.$route.query.searchtype || 1,
        searchname: this.$route.query.searchname || "",
        resourcesno: this.$route.params.id,
      },

      resourcesview: {
        title: "",
        date: "",
        cnt: "",
        contents: "",
        path: "",
        filename: "",
        prevno: "",
        prevtitle: "",
        nextno: "",
        nexttitle: "",
      },
      next_post: {},
      prev_post: {},
    };
  },
  methods: {
    get_resourcesview() {
      ax.get_resourcesview(this.param, (flag, data) => {
        if (flag) {
          this.resourcesview = data.resourcesview;
          this.next_post = data.next_post;
          this.prev_post = data.prev_post;
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_resourcesview();
  },
  watch: {
    "$route.params": function () {
      this.param.resourcesno = parseInt(this.$route.params.id);
      this.get_resourcesview();
    },
  },
};
</script>
