var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('location'),_c('div',{staticClass:"board_view page"},[_c('div',{staticClass:"wrap-1200"},[_c('article',{staticClass:"article-header row g-0"},[_c('div',{staticClass:"col-12"},[_c('h5',[_vm._v(_vm._s(_vm.noticeview.title))])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"col-12 col-sm-6 text-black"},[(_vm.noticeview.type === '1')?_c('span',{staticClass:"icon badge bg-purple"},[_vm._v(" 공지 ")]):_vm._e(),(_vm.noticeview.type === '2')?_c('span',{staticClass:"icon badge bg-blue"},[_vm._v(" 뉴스 ")]):_vm._e(),_vm._v(" 작성자 : "+_vm._s(_vm.noticeview.regname)+" ")]),_c('div',{staticClass:"d-flex col-12 col-sm-6"},[_c('div',{staticClass:"ms-auto"},[_c('span',{staticClass:"me-2"},[_c('i',{staticClass:"material-icons"},[_vm._v("date_range ")]),_vm._v(" "+_vm._s(_vm.noticeview.date)+" ")]),_c('span',[_c('i',{staticClass:"material-icons"},[_vm._v("visibility")]),_vm._v(" "+_vm._s(_vm._f("comma")(_vm.noticeview.cnt))+" ")])])])])]),(_vm.noticeview.filename)?_c('article',{staticClass:"article-attach"},[_c('a',{attrs:{"href":_vm.noticeview.download_link}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("file_download")]),_c('span',[_vm._v(_vm._s(_vm.noticeview.originname))])])]):_vm._e(),_c('article',{staticClass:"article-body"},[_vm._v(" "+_vm._s(_vm.noticeview.contents)+" ")]),_c('article',{staticClass:"article-footer"},[_c('div',{staticClass:"prev",class:!_vm.next_post.notice_web_pk && 'no-data'},[_c('router-link',{staticClass:"text-truncate",attrs:{"to":{
              name: 'NoticeView',
              params: { id: _vm.next_post.notice_web_pk },
            }}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(" expand_less ")]),_c('span',[_vm._v(_vm._s(_vm.next_post.title || "이전 글이 없습니다."))])])],1),_c('div',{staticClass:"next",class:!_vm.prev_post.notice_web_pk && 'no-data'},[_c('router-link',{staticClass:"text-truncate",attrs:{"to":{
              name: 'NoticeView',
              params: { id: _vm.prev_post.notice_web_pk },
            }}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(" expand_more ")]),_c('span',[_vm._v(_vm._s(_vm.prev_post.title || "다음 글이 없습니다."))])])],1)]),_c('div',{staticClass:"btn-bottom-wrapper"},[_c('b-button',{attrs:{"size":"xl","variant":"outline-primary","aria-label":"목록"},on:{"click":function($event){return _vm.$router.push({
              name: 'Notice',
              query: {
                searchtype: _vm.$route.query.searchtype,
                searchname: _vm.$route.query.searchname,
              },
            })}}},[_vm._v("목록")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }